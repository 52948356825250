<template lang="html">
    <b-container>
        <b-card title="Gestion des SMS">
            <filters :status="status" @on-update-status="updateStatus" @on-select-all-status="allstatus" @on-select-none-status="nonestatus"></filters>
            <campaign-table :campaigns="campaignFilteredByStatus" :right="right" @on-refresh="fetchCampaigns"> </campaign-table>
        </b-card>
    </b-container>
</template>

<script>
    import Filters from './filters.vue';
    import CampaignTable from './table.vue';
    export default {
        components: {
            Filters,
            CampaignTable,
        },
        data() {
            return {
                colorAlert: 'success',
                campaigns: [],
                campaignCode: '',
                right: '',
                status: ['1', '0', '30', '35', '7'],
                popupCampaign: false,
                startDateValueCampaign: '',
                endDateValueCampaign: '',
                startDate: '',
                startDateCode: '',
            };
        },
        computed: {
            campaignFilteredByStatus() {
                let result = [];
                if (this.status.includes('0')) {
                    result.push('0', '1');
                }
                if (this.status.includes('35')) {
                    result.push('30', '35', '7');
                }
                if (!this.status.includes('0')) {
                    result.filter((el) => el !== '1');
                }
                if (!this.status.includes('35')) {
                    result.filter((el) => el !== '30' && '7');
                }
                return this.campaigns.filter((camp) => result.includes(camp.status.toString()));
            },
        },
        mounted() {
            this.$root.toggleGlobalLoading(true);
            this.init();
        },
        methods: {
            updateStatus(status) {
                this.$set(this, 'status', status);
            },
            allstatus() {
                this.updateStatus(['0', '1', '30', '35', '7']);
            },
            nonestatus() {
                this.updateStatus([]);
            },

            fetchCampaigns() {
                this.$store
                    .dispatch('app/getSMS')
                    .then((res) => {
                        let campaigns = res.data;
                        this.$set(this, 'campaigns', campaigns);
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur sur la récupération des campagnes',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },

            async init() {
                this.$root.toggleGlobalLoading(true);
                this.updateStatus(['0', '1', '30', '35', '7']);
                this.fetchCampaigns();
            },
        },
    };
</script>

<style></style>
