<template>
    <div>
        <p>Filtrer par status:</p>
        <b-row>
            <b-col cols="9">
                <b-form-checkbox v-model="localStatus" class="mr5 ml5 mt5" value="0" inline> Creé </b-form-checkbox>
                <b-form-checkbox v-model="localStatus" class="mr5 ml5 mt5" value="35" inline> Terminé </b-form-checkbox>
            </b-col>
            <b-button size="sm" class="mr20 mt5 mla fs12 pl28 pr28" variant="primary" @click="allstatus"> Tout sélectionner </b-button>

            <b-button size="sm" class="mr20 mt5 mla fs12 pl28 pr28" variant="primary" @click="nonestatus"> Tout désélectionner </b-button>
            <b-form-group label-for="input-select-brand"> </b-form-group>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: 'filters',
        props: {
            status: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                localStatus: '',
            };
        },
        watch: {
            localStatus() {
                this.$emit('on-update-status', this.localStatus);
            },
            status: {
                handler() {
                    this.$set(this, 'localStatus', this.status);
                },
            },
        },
        methods: {
            allstatus() {
                this.$emit('on-select-all-status');
            },
            nonestatus() {
                this.$emit('on-select-none-status');
            },
        },
    };
</script>

<style></style>
